import React from "react";
import { SvgIcon } from "@mui/material";

const MenuIcon = () => (
  <SvgIcon viewBox="0 0 23 24" fontSize={"inherit"}>
    <path d="M22.3383 22.2868C22.558 22.5065 22.558 22.8625 22.3383 23.082C22.1184 23.3019 21.7627 23.3019 21.543 23.082L11.25 12.7946L0.963264 23.0813C0.743396 23.3012 0.387686 23.3012 0.168029 23.0813C-0.0516973 22.8616 -0.0516973 22.5056 0.168029 22.2861L10.4555 12.0001L0.164795 1.71337C-0.0549316 1.49364 -0.0549316 1.13765 0.164795 0.918134C0.384521 0.698618 0.740514 0.698407 0.960029 0.918134L11.25 11.2056L21.5367 0.918837C21.7564 0.699111 22.1124 0.699111 22.3319 0.918837C22.5515 1.13856 22.5517 1.49456 22.3319 1.71407L12.0445 12.0001L22.3383 22.2868Z" />
  </SvgIcon>
);

export default MenuIcon;
