import axios from "axios";

const EMAIL_TRACKING_URL = 'https://prod-10.westeurope.logic.azure.com/workflows/8e435bd7944a4085b5c3fc0a39910617/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=WvEaQ7LJ_koA3fGTu4zj-a8lpZTHTrnf5shJOqmzJXc'

/**
 * Send email tracking link in JSON POST request
 */
export const sendEmailTrackingLink = async ({leadId}: {leadId: string}) => {
  const data = { leadId }
  try {
    const res = await axios.post(EMAIL_TRACKING_URL, data);
    if (res.status !== 200) {
      console.error(`Error sending email tracking link ${res.status}`);
    }
  } catch (err) {
    console.error(`Error sending email tracking link ${err}`);
  }
}