/**
 * Return window.location.search wihtout a new query string.
 * Uses URLSearchParams if available.
 */
export default function removeQueryStringParameter(
  location: Location,
  parameter
) {
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete(parameter);
  return location.pathname + searchParams.toString() + location.hash;
}
