import { alpha, createTheme, lighten } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      light: "#2855B0",
      main: "#0F3773",
      dark: "#09254D",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#EC6608",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#60A700",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFB13D",
    },
    error: {
      main: "#BD3D44",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      fontFamily: "Montserrat, sans-serif",
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "48px",
      lineHeight: "130%",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
    },
    h4: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "130%",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h5: {
      fontFamily: "Montserrat, sans-serif",
    },
    h6: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "18px",
      lineHeight: "150%",
      fontWeight: 600,
      textAlign: "center",
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "140%",
    },
    body2: {
      fontSize: "16px",
      lineHeight: "140%",
      fontWeight: 400,
    },
  },
});

Theme.components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: Theme.palette.primary.main,
        color: Theme.palette.primary.contrastText,
        borderRadius: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "99px",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        textTransform: "none",
        whiteSpace: "nowrap",
        padding: "11px 27px",
      },
      outlined: {
        border: `1px solid ${lighten(Theme.palette.primary.main, 0.64)}`,
      },
    },
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: "12px",
      },
    },
    defaultProps: {
      elevation: 0,
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "160%",
        color: Theme.palette.primary.main,
      },
      secondary: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "160%",
        color: "#979797",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h2: {
        [Theme.breakpoints.down("md")]: {
          fontSize: "30px",
        },
      },
      h3: {
        fontSize: "28px",
        lineHeight: "160%",
        fontWeight: 700,
        color: Theme.palette.primary.main,
        textAlign: "center",
        [Theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
      },
      h5: {
        fontSize: "18px",
        lineHeight: "150%",
        fontWeight: 700,
        color: Theme.palette.primary.main,
        textAlign: "center",
      },
      h6: {},
      subtitle2: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "160%",
        textAlign: "center",
        color: "#979797",
        "& p": {
          margin: "0",
        },
        "& a": {
          color: "#979797",
        },
      },
      body1: {
        fontSize: "14px",
        lineHeight: "150%",
        opacity: 0.87,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      filled: {
        backgroundColor: "#fff",
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 4,
        transition: Theme.transitions.create([
          "border-color",
          "background-color",
          "box-shadow",
        ]),
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.Mui-focused": {
          backgroundColor: "transparent",
          boxShadow: `${alpha(Theme.palette.primary.main, 0.25)} 0 0 0 2px`,
          borderColor: Theme.palette.primary.main,
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff !important",
        },
      },
    },
    defaultProps: {
      variant: "filled",
      disableUnderline: true,
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 4,
        transition: Theme.transitions.create([
          "border-color",
          "background-color",
          "box-shadow",
        ]),
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.Mui-focused": {
          backgroundColor: "transparent",
          boxShadow: `${alpha(Theme.palette.primary.main, 0.25)} 0 0 0 2px`,
          borderColor: Theme.palette.primary.main,
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff !important",
        },
      },
    },
    defaultProps: {
      variant: "filled",
      InputProps: {
        disableUnderline: true,
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        transform: "unset",
      },
    },
    defaultProps: {
      animation: "wave",
    },
  },
  MuiPagination: {
    styleOverrides: {
      ul: {
        justifyContent: "center",
      },
      outlined: {
        "& .MuiPaginationItem-root, Mui-disabled": {
          width: "64px",
          height: "64px",
          borderRadius: "32px",
          fontSize: "18px",
          fontWeight: 800,
          fontFamily: "Inter, sans-serif",
          color: Theme.palette.primary.dark,
          opacity: "1 !important",
        },
        "& .MuiPaginationItem-page": {
          border: "none",
        },
        "& .MuiPaginationItem-ellipsis": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .Mui-disabled": {
          color: "#939393 !important",
          opacity: "1 !important",
        },
        "& .Mui-selected": {
          backgroundColor: "transparent !important",
          color: Theme.palette.secondary.main,
        },
        "& .MuiPaginationItem-previousNext": {
          color: Theme.palette.secondary.main,
          border: "1px solid #f3f3f3",
          boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
        },
      },
    },
  },
};

export default Theme;
