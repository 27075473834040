import React, { FC, useContext } from "react";
import {
  AppBar,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  Dialog,
  Drawer,
  IconButton,
  Link,
  Stack,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import navigation from "../constants/navigation";
import { useTranslation } from "react-i18next";
import MenuIcon from "./icons/MenuIcon";
import CloseIcon from "./icons/CloseIcon";
import { CTADialogContext } from "../context/CTADialogContext";
import LanguageSwitcher from "./LanguageSwitcher";
import MobileIcon from "./icons/MobileIcon";
import ElevationScroll from "./ElevationScroll";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

const NavigationContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "80px",

  [theme.breakpoints.up("lg")]: {
    maxWidth: "1280px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "56px",
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const NavigationMenuBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "transparent",
})<BoxProps & { transparent?: boolean }>(({ theme, transparent }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: theme.spacing(6),

  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(3),
  },

  "& .MuiLink-root": {
    color: transparent
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "26px",
  },
  "& .MuiButton-root": {
    color: transparent
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText,
    borderColor: transparent
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText,

    "& .MuiTypography-root": {
      color: transparent
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
    },
  },
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  border: `1px solid #ffffff60`,
  marginLeft: theme.spacing(6),
  fontWeight: 500,
  height: "40px",

  "&:hover": {
    border: `1px solid ${theme.palette.primary.contrastText}`,
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(3),
  },
}));

const MenuButton: FC<ButtonProps> = ({ sx, ...props }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Button
      {...props}
      variant={"text"}
      endIcon={<MenuIcon />}
      disableRipple
      sx={{
        ...sx,
        color: theme.palette.primary.contrastText,
        fontWeight: 500,
        height: "40px",
        paddingLeft: {
          xs: "0",
          sm: "27px",
        },
        paddingRight: {
          xs: "8px",
          sm: "27px",
        },
      }}
    >
      {!isPhone && t("navigace.menu")}
    </Button>
  );
};

const CloseButton: FC<ButtonProps> = ({ onClick, children }) => (
  <IconButton
    onClick={onClick}
    sx={{ color: "#fff", width: "56px", height: "56px" }}
  >
    <CloseIcon />
  </IconButton>
);

interface Props {
  transparent?: boolean;
}

const NavigationMenu: FC<Props> = ({ transparent }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const ctaDialogContext = useContext(CTADialogContext);

  const navigateTo = (e: any, route: string, delay = 0) => {
    setIsMenuOpen(false);
    if (location.pathname === "/" && route.startsWith("/#")) {
      e.preventDefault();
      const documentId = route.replace(/^\/|#/g, "");
      const scrollTo = document.getElementById(documentId);
      if (scrollTo) {
        setTimeout(function () {
          scrollTo.scrollIntoView({ behavior: "smooth" });
        }, delay);
      }
    }
  };

  if (isLarge) {
    return (
      <NavigationMenuBox
        transparent={transparent}
        flex="1 1 auto"
        direction="row"
        spacing={1}
      >
        {navigation.map((route) => (
          <Link
            key={route.name}
            href={route.route}
            onClick={(e) => {
              navigateTo(e, route.route);
            }}
            noWrap
          >
            {t(route.label)}
          </Link>
        ))}
        <LanguageSwitcher
          color={transparent ? theme.palette.primary.main : "white"}
        />
        <NavigationButton
          variant={"outlined"}
          onClick={() => ctaDialogContext.setIsDialogOpen(true)}
        >
          {t("navigace.ctaDialog")}
        </NavigationButton>
      </NavigationMenuBox>
    );
  } else if (isMedium) {
    return (
      <>
        <NavigationMenuBox
          transparent={transparent}
          flex="0 0 auto"
          direction="row"
          spacing={2}
        >
          <LanguageSwitcher
            color={transparent ? theme.palette.primary.main : "white"}
          />
          <NavigationButton
            variant={"outlined"}
            onClick={() => ctaDialogContext.setIsDialogOpen(true)}
          >
            {t("navigace.ctaDialog")}
          </NavigationButton>
          <MenuButton onClick={() => setIsMenuOpen(true)} />
        </NavigationMenuBox>
        <Drawer
          anchor={"right"}
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 0,
              backgroundColor: theme.palette.primary.main,
              width: "320px",
            },
          }}
        >
          <AppBar
            component="nav"
            position={"static"}
            elevation={0}
            sx={{ backgroundColor: "transparent" }}
          >
            <Toolbar
              disableGutters
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <CloseButton onClick={() => setIsMenuOpen(false)}></CloseButton>
            </Toolbar>
          </AppBar>
          <Stack
            direction={"column"}
            alignItems={"center"}
            spacing={4}
            pt={5}
            sx={{
              "& .MuiLink-root": {
                color: theme.palette.primary.contrastText,
                fontSize: 16,
                fontWeight: 500,
                lineHeight: "26px",
              },
            }}
          >
            {navigation.map((route) => (
              <Link
                key={route.name}
                href={route.route}
                onClick={(e) => {
                  navigateTo(e, route.route);
                }}
                noWrap
              >
                {t(route.label)}
              </Link>
            ))}
            <NavigationButton
              variant={"outlined"}
              sx={{ mt: "48px !important" }}
              onClick={() => {
                ctaDialogContext.setIsDialogOpen(true);
              }}
            >
              {t("navigace.ctaDialog")}
            </NavigationButton>
            <LanguageSwitcher
              color={transparent ? theme.palette.primary.main : "white"}
            />
          </Stack>
        </Drawer>
      </>
    );
  } else {
    return (
      <>
        <NavigationMenuBox
          transparent={transparent}
          direction="row"
          spacing={1}
        >
          <NavigationButton
            startIcon={
              <MobileIcon
                color={transparent ? theme.palette.primary.main : "white"}
              />
            }
            variant={"outlined"}
            onClick={() => ctaDialogContext.setIsDialogOpen(true)}
            sx={{
              whiteSpace: "break-spaces",
              textAlign: "left",
              fontSize: "12px",
              lineHeight: "14px",
              maxWidth: "120px",
            }}
          >
            {t("navigace.ctaDialog")}
          </NavigationButton>
          <MenuButton onClick={() => setIsMenuOpen(true)} />
        </NavigationMenuBox>
        <Dialog
          fullScreen
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <AppBar
            component="nav"
            position={"static"}
            elevation={0}
            sx={{ backgroundColor: "transparent" }}
          >
            <Toolbar disableGutters>
              <Stack
                direction={"row"}
                spacing={4}
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "scroll",
                }}
              >
                <Box flex={"1 1 auto"} />
                <CloseButton onClick={() => setIsMenuOpen(false)}></CloseButton>
              </Stack>
            </Toolbar>
          </AppBar>
          <Stack
            direction={"column"}
            alignItems={"center"}
            spacing={4}
            pt={5}
            sx={{
              "& .MuiLink-root": {
                color: theme.palette.primary.contrastText,
                fontSize: 16,
                fontWeight: 500,
                lineHeight: "26px",
              },
            }}
          >
            {navigation.map((route) => (
              <Link
                key={route.name}
                href={route.route}
                onClick={(e) => {
                  navigateTo(e, route.route, 250);
                }}
                noWrap
              >
                {t(route.label)}
              </Link>
            ))}
            <NavigationButton
              variant={"outlined"}
              sx={{ mt: "48px !important" }}
              onClick={() => {
                setIsMenuOpen(false);
                ctaDialogContext.setIsDialogOpen(true);
              }}
            >
              {t("navigace.ctaDialog")}
            </NavigationButton>
            <LanguageSwitcher
              color={transparent ? theme.palette.primary.main : "white"}
            />
          </Stack>
        </Dialog>
      </>
    );
  }
};

const Navigation: FC<Props> = ({ transparent }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ElevationScroll>
      <AppBar component="nav">
        <Toolbar
          disableGutters
          sx={{ backgroundColor: transparent ? "#fff" : undefined }}
        >
          <NavigationContainer>
            <Link href="/" sx={{ display: "flex" }}>
              <Box
                component={"img"}
                src={transparent ? "/logo-blue.svg" : "/logo.svg"}
                alt="SMV Invest"
                width={isMobile ? "120px" : "200px"}
                height={isMobile ? "20px" : "32px"}
              />
            </Link>
            <NavigationMenu transparent={transparent} />
          </NavigationContainer>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

export default Navigation;
export { NavigationContainer };
