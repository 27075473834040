import React, { FC, useEffect, useMemo } from "react";
import { useSessionStorage } from "react-use";

const CTADialogContext = React.createContext<{
  isPromoOffer: boolean;
  isDialogOpen: any | null;
  setIsPromoOffer: (isPromo: boolean) => void;
  setIsDialogOpen: (open: any, onlyFirstTime?: boolean) => void;
}>({
  isPromoOffer: false,
  isDialogOpen: false,
  setIsPromoOffer: (isPromo) => {
    return;
  },
  setIsDialogOpen: (open) => {
    return;
  },
});

const CTADialogContextProvider: FC<any> = ({ children }) => {
  const [ctaShowed, setCtaShowed] = useSessionStorage("ctaShowed", false);
  const [isPromoOffer, setIsPromoOffer] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(null);

  const setDialogOpen = (open: any, onlyFirstTime: boolean) => {
    if (onlyFirstTime) {
      if (!ctaShowed) {
        setCtaShowed(true);
        setIsDialogOpen(open);
      }
    } else {
      setIsDialogOpen(open);
    }
  }

  const value = useMemo(
    () => ({
      isDialogOpen,
      setIsDialogOpen: setDialogOpen,
      isPromoOffer,
      setIsPromoOffer,
    }),
    [isDialogOpen, setDialogOpen]
  );

  useEffect(() => {
    if (isDialogOpen) {
      window.dataLayer.push({ event: "form_konzultace_zdarma_kliknuti" });
    }
  }, [isDialogOpen]);

  return (
    <CTADialogContext.Provider value={value}>
      {children}
    </CTADialogContext.Provider>
  );
};

export default CTADialogContextProvider;

export { CTADialogContext };
