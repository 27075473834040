import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

const Icon: FC<{ color?: string }> = ({ color }) => (
  <SvgIcon viewBox="0 0 12 22" sx={{ height: "26px", width: "13px" }}>
    <path
      d="M4.8 15.849C4.64087 15.849 4.48826 15.9185 4.37574 16.0423C4.26321 16.1661 4.2 16.334 4.2 16.509C4.2 16.6841 4.26321 16.852 4.37574 16.9757C4.48826 17.0995 4.64087 17.1691 4.8 17.1691H7.2C7.35913 17.1691 7.51174 17.0995 7.62426 16.9757C7.73679 16.852 7.8 16.6841 7.8 16.509C7.8 16.334 7.73679 16.1661 7.62426 16.0423C7.51174 15.9185 7.35913 15.849 7.2 15.849H4.8ZM2.4 0.00854492C1.76348 0.00854492 1.15303 0.286695 0.702944 0.781806C0.252856 1.27692 0 1.94843 0 2.64862V18.4891C0 19.1893 0.252856 19.8608 0.702944 20.3559C1.15303 20.851 1.76348 21.1292 2.4 21.1292H9.6C10.2365 21.1292 10.847 20.851 11.2971 20.3559C11.7471 19.8608 12 19.1893 12 18.4891V2.64862C12 1.94843 11.7471 1.27692 11.2971 0.781806C10.847 0.286695 10.2365 0.00854492 9.6 0.00854492H2.4ZM1.2 2.64862C1.2 2.29853 1.32643 1.96277 1.55147 1.71521C1.77652 1.46766 2.08174 1.32858 2.4 1.32858H9.6C9.91826 1.32858 10.2235 1.46766 10.4485 1.71521C10.6736 1.96277 10.8 2.29853 10.8 2.64862V18.4891C10.8 18.8392 10.6736 19.1749 10.4485 19.4225C10.2235 19.6701 9.91826 19.8091 9.6 19.8091H2.4C2.08174 19.8091 1.77652 19.6701 1.55147 19.4225C1.32643 19.1749 1.2 18.8392 1.2 18.4891V2.64862Z"
      fill={color}
    />
  </SvgIcon>
);

export default Icon;
