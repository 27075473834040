import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

const MenuIcon: FC<{ color?: string }> = ({ color }) => (
  <SvgIcon viewBox="0 0 24 20">
    <path
      d="M0 1.28561C0 0.812038 0.383786 0.428467 0.857143 0.428467H23.1429C23.6143 0.428467 24 0.812038 24 1.28561C24 1.75918 23.6143 2.14275 23.1429 2.14275H0.857143C0.383786 2.14275 0 1.75918 0 1.28561ZM0 9.85704C0 9.38561 0.383786 8.9999 0.857143 8.9999H23.1429C23.6143 8.9999 24 9.38561 24 9.85704C24 10.3285 23.6143 10.7142 23.1429 10.7142H0.857143C0.383786 10.7142 0 10.3285 0 9.85704ZM23.1429 19.2856H0.857143C0.383786 19.2856 0 18.8999 0 18.4285C0 17.957 0.383786 17.5713 0.857143 17.5713H23.1429C23.6143 17.5713 24 17.957 24 18.4285C24 18.8999 23.6143 19.2856 23.1429 19.2856Z"
      fill={color}
    />
  </SvgIcon>
);

export default MenuIcon;
