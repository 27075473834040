import React, { FC, useMemo } from "react";

const OrderDialogContext = React.createContext({
  instrument: false,
  leadOnly: false,
  setInstrument: (open) => {
    return;
  },
  setLead: (open) => {
    return;
  }
});

const OrderDialogContextProvider: FC<any> = ({ children }) => {
  const [leadOnly, setLeadOnly] = React.useState(false);
  const [instrument, setInstrument] = React.useState(false);

  const value = useMemo(
    () => ({
      instrument,
      leadOnly,
      setInstrument: (instrument) => {
        setLeadOnly(false);
        setInstrument(instrument);
      },
      setLead: (instrument) => {
        setLeadOnly(true);
        setInstrument(instrument);
      },
    }),
    [instrument, setInstrument]
  );

  return (
    <OrderDialogContext.Provider value={value}>
      {children}
    </OrderDialogContext.Provider>
  );
};

export default OrderDialogContextProvider;

export { OrderDialogContext };
