export default [
  {
    name: "projekty",
    route: "/#projekty",
    label: "navigace.projekty",
  },
  {
    name: "hodnoceni",
    route: "/#hodnoceni",
    label: "navigace.hodnoceni",
  },
  {
    name: "dluhopisy",
    route: "/#dluhopisy",
    label: "navigace.dluhopisy",
  },
  {
    name: "realizace",
    route: "/#realizace",
    label: "navigace.realizace",
  },
  {
    name: "o-nas",
    route: "/#o-nas",
    label: "navigace.oNas",
  },
  {
    name: "dotazy",
    route: "/#dotazy",
    label: "navigace.dotazy",
  },
  {
    name: "kontakt",
    route: "/#kontakt",
    label: "navigace.kontakt",
  },
];
