import React from "react";
import Navigation from "./components/Navigation";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CTADialogContextProvider from "./context/CTADialogContext";
import OrderDialogContextProvider from "./context/OrderDialogContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/cs";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./b2cConfig";
import { MsalProvider } from "@azure/msal-react";
import { sendEmailTrackingLink } from "./services/tracking";
import "url-search-params-polyfill";
import removeQueryStringParameter from "./utils/removeQueryStringParameter";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

import "components/phoneInput.css";

ReactPixel.init("582769493662803");

const client = new ApolloClient({
  uri: "https://cms.smvinvest.cz/cms/graphql",
  cache: new InMemoryCache(),
});

const Main = React.lazy(() => import("./pages/Main"));
const ForInvestors = React.lazy(() => import("./pages/ForInvestors"));
const Career = React.lazy(() => import("./pages/Career"));
const Document = React.lazy(() => import("./pages/Document"));
const Page = React.lazy(() => import("./pages/Page"));
const Project = React.lazy(() => import("./pages/Project"));
const Thanks = React.lazy(() => import("./pages/Thanks"));
const THX = React.lazy(() => import("./pages/THX"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BlogArticle = React.lazy(() => import("./pages/BlogArticle"));

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const localeMap = {
  en: "en-in",
  cs: "cs",
};

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    (event.payload as any).account
  ) {
    msalInstance.setActiveAccount((event.payload as any).account);
  }
});

function App() {
  const { i18n } = useTranslation();

  // Facebook Pixel page view
  React.useEffect(() => {
    if (window.location.pathname === "/fb") {
      ReactPixel.pageView();
    }
  }, [window.location.pathname]);

  // Email link handler
  // Url with parameter LeadId is handled by API endpoint
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadId = urlParams.get("LeadId");
    if (leadId) {
      sendEmailTrackingLink({ leadId });
      // remove leadId from url
      window.history.replaceState(
        {},
        document.title,
        removeQueryStringParameter(window.location, "LeadId"),
      );
    }
  }, [window.location.search]);

  return (
    <ApolloProvider client={client}>
      <MsalProvider instance={msalInstance}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={localeMap[i18n.language] ?? "cs"}
        >
          <CTADialogContextProvider value={false}>
            <OrderDialogContextProvider value={null}>
              <Router>
                <Routes>
                  <Route path={"/dekujeme"} element={<></>} />
                  <Route
                    path={"/blog/*"}
                    element={<Navigation transparent />}
                  />
                  <Route path={"*"} element={<Navigation />} />
                </Routes>
                <Routes>
                  <Route path={"/"} element={<Main />} />
                  <Route path={"/fb"} element={<Main />} />
                  <Route path={"/pro-investory"} element={<ForInvestors />} />
                  <Route path={"/projekt/*"} element={<Project />} />
                  <Route path={"/kariera"} element={<Career />} />
                  <Route path={"/archiv/*"} element={<Document />} />
                  <Route path={"/dekujeme"} element={<Thanks />} />
                  <Route path={"/blog/*"} element={<BlogArticle />} />
                  <Route path={"/blog"} element={<Blog />} />
                  <Route path={"/THX"} element={<THX />} />
                  <Route path="*" element={<Page />} />
                </Routes>
              </Router>
            </OrderDialogContextProvider>
          </CTADialogContextProvider>
        </LocalizationProvider>
      </MsalProvider>
    </ApolloProvider>
  );
}

export default App;
